import {Moment} from "moment";

const moment = require("moment");

/**
 * Returns the literal time of the meet
 *
 *
 * Vor 20 Stunden
 * 20 Hours ago
 *
 * Seit 20 Minuten
 * Since 20 minutes
 *
 * In 20 Minuten
 * In 20 minutes
 */
export function getLiteralTime(timeStart: Moment): string {

    if(!timeStart) {
        return "";
    }

    const isFormer: boolean = moment().isAfter(timeStart);

    const timeVoc = isFormer
        ? "Vor"
        : moment().isAfter(timeStart)
            ? "Seit"
            : "In";
    let timeAmount;

    const days = Math.abs(timeStart.diff(moment(), "days"));
    const hours = Math.abs(timeStart.diff(moment(), "hours"));
    const minutes = Math.abs(timeStart.diff(moment(), "minutes"));

    if (days === 0 && hours !== 0) {
        timeAmount =
            hours +
            " " +
            (hours !== 1 ? "Stunden" : "Stunde");
    } else if (hours === 0) {
        timeAmount =
            minutes +
            " " +
            (minutes !== 1 ? "Minuten" : "Minute");
    } else {
        const day = days > 1 ? "Tage" : "Tag";
        timeAmount = days + " " + day;
    }

    return timeVoc + " " + timeAmount;
}
