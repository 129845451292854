import React, { Component, CSSProperties } from "react";
import "./Landing.css";
import firebase from "../../firebase/firebase";
import {
  MeetConstants,
  MeetDBObject,
  MeetMembersDBObject,
  MemberStatus,
} from "../../constants/Types";
import Colors from "../../constants/Colors";
import EventIcon from "../../components/EventIcon";
import { ClipLoader } from "react-spinners";
import { getLiteralTime } from "../../helpers/dateHelpers";
import { IoIosClose, MdAccessTime } from "react-icons/all";
import { isAndroid } from "react-device-detect";

const queryString = require("query-string");
const moment = require("moment");

interface IState {
  isLoading: boolean;
  meet?: MeetDBObject;
}

type Props = {};

export default class Landing extends Component<Props> {
  state: Readonly<IState> = {
    isLoading: false,
  };

  async componentDidMount(): Promise<void> {
    this.setState({
      isLoading: true,
    });

    let meet: MeetDBObject | undefined = undefined;
    const meetId = queryString.parse(window.location.search).id;

    if (!!meetId) {
      const snapshot = await firebase
        .database()
        .ref("meets")
        .child(meetId)
        .once("value");

      meet = snapshot.val();
    }
    this.setState({
      isLoading: false,
      meet,
    });
  }

  navigateToStores() {
    if (isAndroid) {
      window.open(
        "https://play.google.com/store/apps/details?id=de.meeter.prod&gl=DE",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/de/app/meeter/id1585547219",
        "_blank"
      );
    }
  }

  renderMembers(meet: MeetDBObject) {
    const confirmed: MeetMembersDBObject[] = [];
    const requested: MeetMembersDBObject[] = [];
    const declined: MeetMembersDBObject[] = [];

    Object.values(meet.members).filter((member) => {
      switch (member.status) {
        case MemberStatus.CONFIRMED:
          confirmed.push(member);
          break;
        case MemberStatus.REQUESTED:
          requested.push(member);
          break;
        case MemberStatus.DECLINED:
          declined.push(member);
          break;
      }
    });

    return (
      <div>
        <div style={styles.statusLine}>
          <h3 style={styles.textStatus}>Zugesagt:</h3>
          <h2 style={styles.textStatusValue}>{confirmed.length}</h2>
        </div>
        <div style={styles.statusLine}>
          <h3 style={styles.textStatus}>Eingeladen:</h3>
          <h2 style={styles.textStatusValue}>{requested.length}</h2>
        </div>
        <div style={styles.statusLine}>
          <h3 style={styles.textStatus}>Abgesagt:</h3>
          <h2 style={styles.textStatusValue}>{declined.length}</h2>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, meet } = this.state;

    if (isLoading) {
      return (
        <div style={styles.container}>
          <ClipLoader size={80} color={Colors.mainColor} loading={isLoading} />
        </div>
      );
    }

    if (
      (!isLoading && !meet) ||
      (!!meet && moment().isAfter(moment(meet.time.from)))
    ) {
      return (
        <div style={styles.container}>
          <div style={styles.upperContainer}>
            <div style={styles.errorContainer}>
              <IoIosClose color={Colors.mainColor} size={90} />

              <h2 style={{ fontWeight: 400 }}>
                Der Link ist nicht mehr gültig
              </h2>
            </div>
          </div>
        </div>
      );
    }

    if (!isLoading && !!meet) {
      return (
        <div style={styles.container}>
          <div style={styles.upperContainer} className={"upperContainer"}>
            <div
              style={styles.upperInnerContainer}
              className={"upperInnerContainer"}
            >
              <div style={styles.eventTitleContainer}>
                <EventIcon date={moment(meet.date)} />

                <div style={styles.titleContainer}>
                  <h3 style={styles.textUntil}>
                    {getLiteralTime(moment(meet.time.from))}
                  </h3>
                  <h2 style={styles.textName}>{meet.name}</h2>
                </div>
              </div>

              <div
                onClick={() => this.navigateToStores()}
                style={styles.button}
                className={"button"}
              >
                <h2 style={styles.buttonText}>Beitreten</h2>
              </div>
            </div>

            <div
              style={styles.upperInnerContainer}
              className={"upperInnerContainer"}
            >
              <h2>Teilnehmer</h2>
              {this.renderMembers(meet)}
            </div>

            <div
              style={styles.upperInnerContainer}
              className={"upperInnerContainer"}
            >
              <h2>Allgemeine Informationen</h2>

              {meet.optionals && meet.optionals.description && (
                <div>
                  <h3 style={styles.textDescriptionTitle}>Beschreibung</h3>
                  <p style={styles.textDescription}>
                    {meet.optionals.description}
                  </p>
                </div>
              )}

              <div style={styles.timeContainer}>
                <MdAccessTime color={Colors.mainGrey} size={30} />

                <h5 style={styles.textTime}>Von </h5>
                <h4 style={{ ...styles.textTime, ...styles.big }}>
                  {moment(meet.time.from).format("hh:MM")} Uhr{" "}
                </h4>

                <h5 style={styles.textTime}> bis </h5>

                {meet.time.until !== MeetConstants.OPEN_END && (
                  <h4 style={{ ...styles.textTime, ...styles.big }}>
                    {moment(meet.time.until).format("hh:MM")} Uhr{" "}
                  </h4>
                )}
                {meet.time.until === MeetConstants.OPEN_END && (
                  <h4 style={{ ...styles.textTime, ...styles.big }}>
                    {" "}
                    Open-End{" "}
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const styles: { [key: string]: CSSProperties } = {
  big: {
    fontWeight: "600",
  },
  button: {
    backgroundColor: Colors.signatureBlueLight,
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 40,
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonText: {
    color: Colors.secondaryColor,
    margin: 0,
    fontWeight: "600",
    fontSize: 20,
  },
  container: {
    backgroundColor: Colors.light,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  errorContainer: {
    backgroundColor: Colors.fieryRedLight,
    borderRadius: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 40,
    width: "100%",
  },
  eventTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  textDescriptionTitle: {
    margin: 0,
    marginBottom: 4,
    padding: 0,
    fontSize: 20,
    color: Colors.mainBlack,
    fontWeight: "600",
  },
  textDescription: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    color: Colors.mainBlack,
  },
  statusLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textName: {
    color: Colors.mainBlack,
    margin: 0,
    fontSize: 22,
    marginTop: 2,
  },
  textUntil: {
    color: Colors.grey,
    margin: 0,
    fontWeight: "600",
    fontSize: 16,
    marginBottom: 2,
  },
  textStatus: {
    color: Colors.greyMediumDark,
    fontWeight: "600",
    margin: 0,
    fontSize: 18,
    marginTop: 2,
    textAlign: "left",
    marginRight: 10,
  },
  textStatusValue: {
    fontSize: 22,
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  titleContainer: {
    marginLeft: 30,
    marginRight: 10,
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
  },
  textTime: {
    fontWeight: "500",
    color: Colors.mainBlack,
    fontSize: 16,
    marginLeft: 8,
  },
  upperContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    maxWidth: 1100,
    width: "100%",
    padding: 25,
  },
  upperInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "left",
    backgroundColor: Colors.white,
    padding: 40,
  },
};
