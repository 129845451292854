import { Environment } from "../../index";

/**
 * All environment variables accessible from JavaScript Side
 */
export const ACTIVE_ENVIRONMENT: Environment = {
  name: "DEV",
  config: {
    apiKey: "AIzaSyD5vPfDaSVshz0XxDN8zikduYgCPWFuweA",
    authDomain: "meeter-dev-8ff92.firebaseapp.com",
    databaseURL: "https://meeter-dev-8ff92-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "meeter-dev-8ff92",
    storageBucket: "meeter-dev-8ff92.appspot.com",
    messagingSenderId: "14530089113",
    appId: "1:14530089113:web:a65eda6eb081a4ee976808",
    measurementId: "G-J73614M49G"
  },
};
