import React, {Component, Suspense} from "react";
import {
    BrowserRouter,
    Switch,
    Route,
    Link
    // @ts-ignore
} from "react-router-dom";
import './App.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NothingThere from "./components/404/NothingThere";
import Landing from "./sites/Landing/Landing";

function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={null}>
                <Header/>
                <div className={"content-container"}>
                    <Switch>
                        <Route path="/">
                            <Landing/>
                        </Route>
                        <Route>
                            <NothingThere/>
                        </Route>
                    </Switch>
                </div>
                <Footer/>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
