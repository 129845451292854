const tintColor = "#FD5150";

export default {
    tintColor,
    tabIconDefault: tintColor,
    tabIconSelected: "#FD5150",
    tabBar: "#FD5150",
    errorBackground: "red",
    errorText: "#C40000",
    warningBackground: "#EAEB5E",
    warningText: "#666804",
    noticeBackground: tintColor,
    noticeText: "#fff",
    mainColor: "#FD5150",
    secondaryColor: "#74B9FF",
    notActive: "#9B9B9B",

    mainBlack: "#313131",
    mainGrey: '#4A4A4A',
    // 646161
    greyDark: '#5C5C5C',
    grey: '#828282',
    greyMediumDark: '#979797',
    greyMedium: '#C8C8C8',
    greyMediumLight: '#DFDEDE',
    greySemiLight: '#efefef',
    greyLight: '#F8F8F8',
    light: '#F3F3F3',
    newLight: '#F0F0F0',
    white: '#FFFFFF',

    orange: '#F5A623',
    orangeLight: '#FBDBA6',

    fieryRed: '#FD5150',
    fieryRedLight: '#FBDBDB',

    marine: '#196BD6',
    signatureBlue: '#74B9FF',
    signatureBlueLight: '#E8F3FF',

    green: '#1ABC9C',
    greenLight: '#83D8C1',
    acceptableGreen: '#7ED321'

};
