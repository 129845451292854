import * as React from "react";
import "./Header.css";
import {ReactComponent as Logo} from "../../assets/main_logo.svg";

export default class Header extends React.Component {
    render() {
        return (
            <div className={"header"}>
                <div className={"headerContainer"}>
                    <Logo className={"logo"} width={"auto"}/>
                </div>
            </div>
        );
    }
}
