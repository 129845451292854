import React, {Suspense} from "react";
import "./NothingThere.css";
import Link from "../../constants/Link";
import {useTranslation} from "react-i18next";

export default class NothingThere extends React.Component {
    render() {

        return (

            <div className={"nothing-there-container"}>
                <div>
                </div>
                <RenderText/>
            </div>);
    }
}

function RenderText() {
    const {t} = useTranslation();
    return (
            <div>
                <h4>{t('auth.404Headline')}</h4>
                <h2>{t('auth.404NothingThere')}</h2>
                <p>{t('auth.404Description')}</p>
                <a href={"https://" + Link.main}>{t('auth.toLanding')}</a>
            </div>
    );
}
