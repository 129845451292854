import * as React from "react";
import {Component} from "react";
import {CSSProperties} from "react";
import Colors from "../constants/Colors";
// @ts-ignore
import {Moment} from "moment";

const moment = require("moment");

type Props = {
    // redux data
    date: Moment;
    meetID?: string;
    navigateOnPress?: boolean;
    onPress?: () => void;

    // other props
    style?: any;
};

/**
 * Returns a String of a given Date in the Format DD/MM/YYYY
 *
 * @param date the date
 * @param hasYear if the year should be included
 * @returns {string}
 */
export function getDateStringWithDots(date: Moment, hasYear = true): string {
    return getDateStringWithSeparator(date, hasYear, ".");
}


/**
 * Returns a String of a given Date in the Format DD/MM/YYYY
 *
 * @param date the date
 * @param hasYear if the year should be included
 * @param separator the separator string
 * @returns {string}
 */
export function getDateStringWithSeparator(
    date: Moment,
    hasYear: boolean = true,
    separator: string = "/"
): string {

    if (!date) {
        console.error("date undefined at getDateStringWithSeparator()");
        return "";
    }

    let day: string | number = date.date();
    let month: string | number = date.month() + 1;

    if (day < 10) {
        day = "0" + day;
    }

    if (month < 10) {
        month = "0" + month;
    }

    let dateString = day + separator + month;

    if (hasYear) {
        dateString += separator + date.year().toString().substr(2);
    }

    return dateString;
}

/**
 * Returns the Short Name of a Date from a given Date String
 *
 * @param date as a momentJS Object
 * @param t
 * @returns {any}
 */
export function getShortDateName(date: Moment): string {
    let weekday = new Array(7);
    weekday[0] = "Mon";
    weekday[1] = "Di";
    weekday[2] = "Mi";
    weekday[3] = "Do";
    weekday[4] = "Fr";
    weekday[5] = "Sa";
    weekday[6] = "So";

    return weekday[date.weekday()];
}

/**
 * The component to show a date as a calendar
 */
export default class EventIcon extends Component<Props> {
    static defaultProps = {
        size: "normal",
    };

    render() {
        const {meetID, navigateOnPress, date} = this.props;

        const dateString = getDateStringWithDots(date);

        return (
            <div
                style={styles.big}
            >
                <div style={styles.date}>
                    <h2 style={styles.text}>{dateString}</h2>
                </div>
                <div style={styles.lowerContainer}>
                    <h3 style={styles.dateName}>{date && getShortDateName(date)}.</h3>
                </div>

            </div>
        );
    }
}

const styles: { [key: string]: CSSProperties } = {
    big: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: Colors.white,
        borderRadius: 10,
        width: 80,
        height: 80,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)"
    },
    date: {
        backgroundColor: Colors.fieryRed,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingTop: 8,
        paddingBottom: 8,
        width: "100%",
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
    },
    dateName: {
        color: Colors.mainGrey,
        fontSize: 25,
        fontWeight: "700",
        textAlign: "center",
        margin: 0,
        padding: 0
    },
    lowerContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    text: {
        color: Colors.white,
        fontSize: 15,
        textAlign: "center",
        fontWeight: "600",
        margin: 0,
        padding: 0
    },
};
