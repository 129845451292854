
export type MeetDBObject = {
    admins: {
        [adminUID: string]: boolean;
    };
    allowRequests: boolean;
    allowPictureEditing: boolean;
    needCovidVaccine: boolean;
    allowVoting: boolean;
    date: string;
    imageURL?: string;
    link?: string;
    members: {
        [userUID: string]: MeetMembersDBObject;
    };
    name: string;
    optionals: MeetOptionals;
    time: MeetTime;
    shareType: ShareType;
};

export type MeetMembersDBObject = {
    invitedFrom: string;
    status: string;
    userUID: string;
};

export type MeetOptionals = {
    costs?: { amount: number; type: CostType };
    maxMembers?: { amount: string; type: UserBoundType };
    bringAlongList?: {
        everyCanAdd: boolean;
        limitsCouldBrake: boolean;
        values: BringAlongListValues;
    };
    description?: string;
    location?: string | Place;
};

export enum CostType {
    PP = "pP",
    SUM = "total",
}

export enum UserBoundType {
    MIN = "min",
    MAX = "max",
}

export type Place = {
    longitude: number;
    latitude: number;
    address: string;
};

export type BringAlongListValues = {
    everybody: {
        [name: string]: BringListElement;
    };
    someoneBrings: {
        [name: string]: SomeoneBringsElement;
    };
    nobodyBrings: {
        [name: string]: BringListElement;
    };
};

export type BringListElement = {
    name: string;
    amount: number;
};

export type SomeoneBringsElement = BringListElement & {
    broughtBy: {
        [bringUserUID: string]: {
            bringAmount: number;
        };
    };
};

export type MeetTime = {
    from?: string;
    until?: string;
};

export enum ShareType {
    PUBLIC = "public",
    PRIVATE = "private",
}

export enum MemberStatus {
    CONFIRMED = "confirmed",
    REQUESTED = "requested",
    DECLINED = "declined",
    REMOVED = "removed",
    CHOSEN = "chosen",
}

export enum MeetConstants {
    OPEN_END = "open-end",
    DATE = "date",
    TIME = "time",
    NAME = "name",
    MEMBERS = "members",
    IMAGE_URL = "imageURL",
    LINK = "link",
}
