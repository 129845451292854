import * as React from "react";
import "./Footer.css";
import Logo from "../../assets/main_logo.png";

export default class Footer extends React.Component {
    render() {
        return (
            <div className={"footer"}>
                <div className={"footerContainer"}>
                    <img src={Logo} className={"footerLogo"} />

                    <div className={"linkContainer"}>
                        <a href={"https://www.acami.io/impressum"}>Impressum</a>
                        <a href={"https://www.acami.io/dataprivacy"}>Datenschutz</a>
                    </div>
                </div>
            </div>
        );
    }
}
